import React from "react";

const Timer = ({ time, mode }) => {
    return(
        <div className={"circle "+ (mode==="EASY" ? "fixed" : (mode==="HARD" ? "alarm" : ""))}>
            <span className="time" id="display">{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:{("0" + Math.floor((time / 1000) % 60)).slice(-2)}.{("0" + ((time / 10) % 100)).slice(-2)}</span>
        </div>
    )
    
}

export default Timer;