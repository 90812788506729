/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTimerState = /* GraphQL */ `
  mutation CreateTimerState(
    $input: CreateTimerStateInput!
    $condition: ModelTimerStateConditionInput
  ) {
    createTimerState(input: $input, condition: $condition) {
      id
      isActive
      isPaused
      state
      mode
      createdAt
      updatedAt
    }
  }
`;
export const updateTimerState = /* GraphQL */ `
  mutation UpdateTimerState(
    $input: UpdateTimerStateInput!
    $condition: ModelTimerStateConditionInput
  ) {
    updateTimerState(input: $input, condition: $condition) {
      id
      isActive
      isPaused
      state
      mode
      createdAt
      updatedAt
    }
  }
`;
export const deleteTimerState = /* GraphQL */ `
  mutation DeleteTimerState(
    $input: DeleteTimerStateInput!
    $condition: ModelTimerStateConditionInput
  ) {
    deleteTimerState(input: $input, condition: $condition) {
      id
      isActive
      isPaused
      state
      mode
      createdAt
      updatedAt
    }
  }
`;
export const createLeaderboard = /* GraphQL */ `
  mutation CreateLeaderboard(
    $input: CreateLeaderboardInput!
    $condition: ModelLeaderboardConditionInput
  ) {
    createLeaderboard(input: $input, condition: $condition) {
      id
      mode
      playTime
      elapsedTime
      createdAt
      updatedAt
    }
  }
`;
export const updateLeaderboard = /* GraphQL */ `
  mutation UpdateLeaderboard(
    $input: UpdateLeaderboardInput!
    $condition: ModelLeaderboardConditionInput
  ) {
    updateLeaderboard(input: $input, condition: $condition) {
      id
      mode
      playTime
      elapsedTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteLeaderboard = /* GraphQL */ `
  mutation DeleteLeaderboard(
    $input: DeleteLeaderboardInput!
    $condition: ModelLeaderboardConditionInput
  ) {
    deleteLeaderboard(input: $input, condition: $condition) {
      id
      mode
      playTime
      elapsedTime
      createdAt
      updatedAt
    }
  }
`;
