import React from "react";
import { FaRegSave, FaTrash } from 'react-icons/fa';

const Controls = ({ handleSaveResult, handleDiscardResult, isActive, isPaused }) => {
     return(
        <div className="controls">
        { (isActive === true && isPaused === true) &&
        <span>
            <button onClick={handleSaveResult}><FaRegSave/></button>
            <button onClick={handleDiscardResult}><FaTrash/></button>
        </span>
        }
        </div>
    )

}

export default Controls;
