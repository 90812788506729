/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateTimerState = /* GraphQL */ `
  subscription OnCreateTimerState {
    onCreateTimerState {
      id
      isActive
      isPaused
      state
      mode
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTimerState = /* GraphQL */ `
  subscription OnUpdateTimerState {
    onUpdateTimerState {
      id
      isActive
      isPaused
      state
      mode
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTimerState = /* GraphQL */ `
  subscription OnDeleteTimerState {
    onDeleteTimerState {
      id
      isActive
      isPaused
      state
      mode
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLeaderboard = /* GraphQL */ `
  subscription OnCreateLeaderboard {
    onCreateLeaderboard {
      id
      mode
      playTime
      elapsedTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLeaderboard = /* GraphQL */ `
  subscription OnUpdateLeaderboard {
    onUpdateLeaderboard {
      id
      mode
      playTime
      elapsedTime
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLeaderboard = /* GraphQL */ `
  subscription OnDeleteLeaderboard {
    onDeleteLeaderboard {
      id
      mode
      playTime
      elapsedTime
      createdAt
      updatedAt
    }
  }
`;
