import React, { useState } from "react";
import Timer from './Timer'

const Stopwatch = ({ handleElapsedTime, isActive, isPaused, state, mode }) => {
    const [time, setTime] = useState(0);

    React.useEffect(() => {
      let interval = null;

      if (isActive && isPaused === false) {
        interval = setInterval(() => {
            setTime((time) => time + 10);
          }, 10);
      } else if (isActive === false && isPaused === false) {
          setTime(0);
          clearInterval(interval);
      } else if (isActive === true && isPaused === true)
      {
        handleElapsedTime(time)
      } return () => {
          clearInterval(interval);
        };
    }, [isActive, isPaused, time, handleElapsedTime]);

    return (
      <div className="stopwatch">
          <Timer time={time} mode={mode}/>
      </div>
      );
}    

export default Stopwatch